import React, { Fragment, useEffect, useState } from "react";
import {
  mergeFullname,
  getLoginProfile,
  fetchData,
  showUploadImageSrc,
} from "./js/main";
import conf from "./conf";
import axios from "axios";
import { Grid, Icon } from "@mui/material";

export default function Card() {
  const [profile, setProfile] = useState();
  const [students, setStudents] = useState([]);
  const [img, setImg] = useState();
  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      let students = await fetchData("post", "/parents/profile", {
        userId: Profile.userId,
      });
      if (students.length > 0) {
        Profile = students[0];
        Profile.fullname = await mergeFullname(
          Profile.prefix,
          Profile.first_name,
          Profile.middle_name,
          Profile.last_name
        );

        let arrStudent = [];
        for (let st of students) {
          console.log(st);
          let key = await arrStudent.findIndex(
            (j) => j._id === st.student_id._id
          );
          if (key < 0) {
            if (st.student_id.photo_url) {
              st.student_id.img = await showUploadImageSrc(
                st.student_id.photo_url
              );
            } else {
              st.student_icon = "person";
            }
            st.student_id.relation = st.relation;
            arrStudent.push(st.student_id);
          }
        }
        setStudents(arrStudent);
        setProfile(Profile);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (profile) {
        let img = profile.line_profile.pictureUrl;
        if (profile.photo) {
          let response = await axios.get(
            conf.endpoint.file + "/" + profile.photo
          );
          img = response.data;
        }
        setImg(img);
      }
    })();
  }, [profile]);

  return (
    <div>
      {profile && (
        <>
          <div
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/card_background.png)`,
              textAlign: "center",
              padding: "30px 20px",
              backgroundPosition: "center",
            }}
          >
            {img && (
              <img
                src={img}
                style={{ maxWidth: "200px", width: "80%" }}
                alt=""
              />
            )}
            <div
              style={{
                marginBottom: "8px",
                fontSize: "1.2rem",
                marginTop: "15px",
                fontWeight: 600,
              }}
            >
              {profile.fullname}
            </div>
          </div>
          <div style={{ background: "#18488a", textAlign: "center" }}>
            <img
              src={process.env.PUBLIC_URL + "/img/middle_card.png"}
              style={{ height: "90px" }}
              alt=""
            />
          </div>
          <div
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/img/card_background.png)`,
              textAlign: "center",
              padding: "30px 20px",
              backgroundPosition: "center",
            }}
          >
            {students.map((student, key) => (
              <Grid container key={key} sx={{ marginTop: "20px" }}>
                <Grid item xs={6} align="right" sx={{ paddingRight: "20px" }}>
                  {student.img ? (
                    <img
                      src={student.img}
                      style={{ maxWidth: "100px", width: "80%" }}
                      alt=""
                    />
                  ) : (
                    <Icon sx={{ fontSize: "6rem", color: "#666" }}>face5</Icon>
                  )}
                </Grid>
                <Grid item xs={6} sx={{ paddingTop: "15px" }} align="left">
                  <div style={{ fontWeight: "500" }}>
                    {student.prefix} {student.first_name} {student.middle_name}{" "}
                    {student.last_name}
                  </div>
                  <div
                    className="bold"
                    style={{ color: "#161", marginTop: "5px" }}
                  >
                    {student.nickname}{" "}
                    {student.current_group &&
                      "(" + student.current_group.name + ")"}
                  </div>
                  <div
                    className="bold"
                    style={{
                      marginTop: "5px",
                      fontSize: ".9rem",
                    }}
                  >
                    ความสัมพันธ์ : {student.relation}
                  </div>
                </Grid>
              </Grid>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
