import React, { useEffect, useState } from "react";
import { getLoginProfile } from "./js/main";
// import liff from "@line/liff";
import conf from "./conf";
import { Button, Container, Icon, Stack, Box } from "@mui/material";

export default function Home() {
  const [profile, setProfile] = useState();
  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      setProfile(Profile);
    })();
  }, []);

  /*
  useEffect(() => {
    (async () => {
      await liff.init({
        liffId: conf.liffId,
        // withLoginOnExternalBrowser: true,
      });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          // let Profile = await liff.getProfile();
          // setProfile(Profile);
        } else {
          if (conf.on_dev === true) {
            // setProfile(conf.lineDevProfile);
          } else {
            liff.login();
          }
        }
      });
    })();
  }, []);
  */
  return (
    <Container>
      {profile && (
        <Box>
          <Stack spacing={2}>
            <h1
              style={{
                textAlign: "center",
                fontWeight: 600,
                marginBottom: "10px",
              }}
            >
              Main Menu
            </h1>
            <Button
              component="a"
              href={process.env.PUBLIC_URL + "/pickup"}
              size="large"
              variant="outlined"
              startIcon={<Icon>call_missed_outgoing</Icon>}
            >
              Pickup Request
            </Button>
            <Button
              component="a"
              href={process.env.PUBLIC_URL + "/confirm"}
              size="large"
              variant="outlined"
              startIcon={<Icon>done</Icon>}
            >
              Pickup Confirm
            </Button>
            {/* <Button
              component="a"
              href={process.env.PUBLIC_URL + "/selectstudent/edit"}
              size="large"
              variant="outlined"
              startIcon={<Icon>edit</Icon>}
            >
              Edit Profile
            </Button> */}
            <Button
              component="a"
              href={process.env.PUBLIC_URL + "/message"}
              size="large"
              variant="outlined"
              startIcon={<Icon>send</Icon>}
            >
              Create Ticket
            </Button>
            <Button
              component="a"
              href={process.env.PUBLIC_URL + "/assign"}
              size="large"
              variant="outlined"
              startIcon={<Icon>person_add</Icon>}
            >
              Assign Another
            </Button>
            <Button
              component="a"
              href={process.env.PUBLIC_URL + "/lineauth"}
              size="large"
              variant="outlined"
              startIcon={<Icon>person_add_alt1</Icon>}
            >
              Add Student
            </Button>
            <Button
              component="a"
              href={process.env.PUBLIC_URL + "/card"}
              size="large"
              variant="outlined"
              startIcon={<Icon>credit_card</Icon>}
            >
              Virtual Card
            </Button>
            <Button
              onClick={() => {
                localStorage.removeItem(conf.cookie_name);
                window.location = process.env.PUBLIC_URL + "/";
              }}
              size="large"
              variant="outlined"
              startIcon={<Icon>lock_open</Icon>}
            >
              Sign Out
            </Button>
          </Stack>
        </Box>
      )}
    </Container>
  );
}
