const on_dev = false;

module.exports = {
  on_dev,
  // dev_access_token: "d765a378-ca61-4a4d-8551-658c832df44d",
  /*
  lineDevProfile: {
    userId: "U8ab2fad5affd3e344a9635e30e83fcfe",
    displayName: "Pratch Sanguansak",
    pictureUrl:
      "https://profile.line-scdn.net/0m07dfed987251d4e7903bb8667a7af0c77bb6d67ac8ff",
  },
  */
  lineDevProfile: {
    userId: "Ud900dc7a5921b8d29ca84847924f258d",
    displayName: "🔜🏀Suphaphit] May🌀🔚",
    pictureUrl:
      "https://profile.line-scdn.net/0hidw9xCA3NktaQBlXNsVINCoQNSF5MW9ZfyR8eGdGbC9jc3JKIXYpempEaX1nI3geJXZ9K2hBYS5WU0EtRBbKf11waHxgcXgZdiVxqg",
  },
  liffId: "1660851296-z7YmVBxe",
  endpoint: {
    tonkla:
      on_dev === true
        ? "http://localhost:3001"
        : "https://tonkla-backend.azurewebsites.net",
    file: "https://tca.tonkla.ac.th/upload",
  },
  cookie_name: "tonkla-parents-cookie",
};
