import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LineLogin from "./LineLogin";
import Pickup from "./Pickup";
import Confirm from "./Confirm";
import Home from "./Home";
import Assign from "./Assign";
import EditPerson from "./EditPerson";
import SelectStudent from "./SelectStudent";
import Message from "./Message";
import Card from "./Card";
function App() {
  const [pages, setPages] = useState();
  useEffect(() => {
    setPages([
      {
        path: "/",
        component: Home,
      },
      {
        path: "/lineauth",
        component: LineLogin,
      },
      {
        path: "/pickup",
        component: Pickup,
      },
      {
        path: "/assign",
        component: Assign,
      },
      {
        path: "/confirm",
        component: Confirm,
      },
      {
        path: "/edit/:student_id",
        component: EditPerson,
      },
      {
        path: "/selectstudent/:path",
        component: SelectStudent,
      },
      {
        path: "/message",
        component: Message,
      },
      {
        path: "/card",
        component: Card,
      },
    ]);
  }, []);

  return (
    <div className="App">
      {pages && (
        <BrowserRouter>
          <Routes>
            {pages
              .filter((j) => j.component)
              .map((page, key) => (
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}${page.path}`}
                  element={<page.component page={page} />}
                  key={key}
                />
              ))}
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
