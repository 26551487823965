import axios from "axios";
import conf from "../conf";
import liff from "@line/liff";
import { v4 as uuidv4 } from "uuid";

export function getLoginProfile() {
  return new Promise(async (resolve) => {
    let cookie = localStorage.getItem(conf.cookie_name);
    if (cookie && cookie.userId && cookie.displayName && cookie.pictureUrl) {
      cookie = JSON.parse(cookie);
      console.log("GET C Profile");
      resolve(cookie);
    } else {
      await liff.init({
        liffId: conf.liffId,
        // withLoginOnExternalBrowser: true,
      });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          let Profile = await liff.getProfile();
          console.log("GET L Profile");
          localStorage.setItem(conf.cookie_name, JSON.stringify(Profile));
          resolve(Profile);
        } else {
          if (conf.on_dev === true) {
            localStorage.setItem(
              conf.cookie_name,
              JSON.stringify(conf.lineDevProfile)
            );
            resolve(conf.lineDevProfile);
          } else {
            liff.login();
          }
        }
      });
    }
  });
}

export function fetchData(method, path, data, auth) {
  return new Promise(async (resolve) => {
    try {
      let cookie = JSON.parse(localStorage.getItem(conf.cookie_name));
      let headers = null;
      // if (conf.on_dev === true && !auth) auth = conf.dev_access_token;
      if (cookie && cookie.access_token)
        headers = { Authorization: cookie.access_token };
      else if (auth) headers = { Authorization: auth };
      let result = await axios({
        method,
        url: conf.endpoint.tonkla + path,
        data,
        headers,
      });
      resolve(result.data);
    } catch (e) {
      if (e.response.data && e.response.data.error)
        alert(e.response.data.error.message);
      resolve(null);
    }
  });
}

export function mergeFullname(prefix, first_name, middle_name, last_name) {
  return new Promise(async (resolve) => {
    if (middle_name) middle_name += " ";
    else middle_name = "";
    let fullname =
      (prefix || "") +
      " " +
      (first_name || "") +
      " " +
      middle_name +
      (last_name || "");
    resolve(fullname);
  });
}

export function showUploadImageSrc(path) {
  return new Promise(async (resolve) => {
    let src = await axios.get("https://tca.tonkla.ac.th/upload/" + path);
    if (src && src.data) resolve(src.data);
    else resolve(null);
  });
}

export function uploadFileStream(stream) {
  return new Promise(async (resolve) => {
    let len = stream.length;
    let packageSize = 50000;
    let packageCount = Math.ceil(len / packageSize);
    let uuid = uuidv4();
    let file_id = null;
    for (let i = 0; i < packageCount; i++) {
      let front = i * packageSize;
      let tail = front + packageSize;
      if (tail > len) tail = len;
      let pack = {
        stream: stream.substring(front, tail),
        package_no: i + 1,
        uuid,
        packageCount,
        file_type: "pickup",
      };
      let result = await axios.post(
        conf.endpoint.file + "/uploadstream.php",
        pack
      );
      if (result.data.file_id && !file_id) {
        file_id = result.data;
      }
    }
    resolve(file_id);
  });
}
