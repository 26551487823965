import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
// import liff from "@line/liff";
// import conf from "./conf";
import { fetchData } from "./js/main";
import { Button, Grid, Icon, Box, TextField, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import { mergeFullname, getLoginProfile } from "./js/main";
import { useNavigate } from "react-router-dom";

export default function Pickup() {
  const [profile, setProfile] = useState();
  const [students, setStudents] = useState();
  const [finish, setFinish] = useState(false);
  const [form, setForm] = useState({
    fullname: "",
    relation: "",
    pickup_date: new Date(),
  });
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      setProfile(Profile);
    })();
  }, []);

  /*
  useEffect(() => {
    (async () => {
      await liff.init({
        liffId: conf.liffId,
        // withLoginOnExternalBrowser: true,
      });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          let Profile = await liff.getProfile();
          setProfile(Profile);
        } else {
          if (conf.on_dev === true) {
            setProfile(conf.lineDevProfile);
          } else {
            liff.login();
          }
        }
      });
    })();
  }, []);
  */

  useEffect(() => {
    (async () => {
      if (profile) {
        let students = await fetchData("post", "/reg/pickup/student", {
          userId: profile.userId,
        });
        for (let s of students) {
          s.fullname = await mergeFullname(
            s.prefix,
            s.first_name,
            s.middle_name,
            s.last_name
          );
          s.fullname_en = await mergeFullname(
            s.prefix_en,
            s.first_name_en,
            s.middle_name_en,
            s.last_name_en
          );
        }
        setStudents(students);
      }
    })();
  }, [profile]);

  async function save() {
    let send = JSON.parse(JSON.stringify(form));
    send.pickup_date = moment(send.pickup_date).format("YYYY-MM-DD");
    send.student_ids = await students
      .filter((j) => j.checked === true)
      .map((j) => j._id);
    send.line_user_id = profile.userId;
    let result = await fetchData("put", "/reg/pickup/assign", send);
    if (result) {
      alert("Assign completed");
      setFinish(true);
    }
  }

  function selectStudent(key) {
    let Students = JSON.parse(JSON.stringify(students));
    Students[key].checked = !Students[key].checked;
    setStudents(Students);
  }
  return (
    <Container style={{ paddingTop: "10px" }}>
      {profile && (
        <Box sx={{ flexGrow: 1 }}>
          <h2 style={{ fontWeight: 600 }}>Assign Another</h2>

          {students && (
            <>
              <List component={Paper}>
                {students.map((student, key) => (
                  <StudentItem
                    key={key}
                    student={student}
                    selectStudent={() => selectStudent(key)}
                  />
                ))}
              </List>
            </>
          )}
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker", "StaticDatePicker"]}>
                  <DatePicker
                    component={TextField}
                    format="DD MMM YYYY"
                    fullWidth
                    value={dayjs(form.pickup_date)}
                    label="Pickup Date"
                    style={{ width: "600px" }}
                    onChange={(val) =>
                      setForm({ ...form, pickup_date: new Date(val) })
                    }
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Pickup Time"
                placeholder="เวลามารับ"
                InputLabelProps={{ shrink: true }}
                value={form.assign_time}
                type="time"
                onChange={(e) =>
                  setForm({ ...form, assign_time: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Fullname"
                placeholder="ชื่อ-นามสกุล ผู้รับแทน"
                InputLabelProps={{ shrink: true }}
                value={form.fullname}
                onChange={(e) => setForm({ ...form, fullname: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Relation"
                placeholder="ความสัมพันธ์"
                InputLabelProps={{ shrink: true }}
                value={form.relation}
                onChange={(e) => setForm({ ...form, relation: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                sx={{ marginTop: "20px" }}
                fullWidth
                variant="contained"
                startIcon={<Icon>person_add</Icon>}
                onClick={save}
                disabled={
                  form.fullname === "" ||
                  form.relation === "" ||
                  students.filter((j) => j.checked === true).length === 0 ||
                  finish === true
                }
              >
                Assign
              </Button>
              <Button
                sx={{ marginTop: "10px" }}
                fullWidth
                color="error"
                variant="contained"
                startIcon={<Icon>arrow_back</Icon>}
                onClick={() => navigate(process.env.PUBLIC_URL + "/pickup")}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
}

function StudentItem({ student, selectStudent }) {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={() => selectStudent()}>
        <ListItemIcon>
          {student.pending === true ? (
            <Icon style={{ fontSize: "2.2rem" }}>access_time</Icon>
          ) : (
            <>
              {student.checked === true ? (
                <Icon style={{ color: "#6a6", fontSize: "2rem" }}>done</Icon>
              ) : (
                <Icon>radio_button_unchecked</Icon>
              )}
            </>
          )}
        </ListItemIcon>
        <ListItemText>
          <div style={{ fontWeight: 500 }}>{student.fullname}</div>
          <div style={{ fontSize: ".9rem", color: "#999" }}>
            {student.fullname_en}
          </div>
          <div style={{ fontSize: ".8rem", color: "#090" }}>
            Pickup person : {student.curator_fullname || "-"}
          </div>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
