import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

// import liff from "@line/liff";
// import conf from "./conf";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  fetchData,
  showUploadImageSrc,
  mergeFullname,
  getLoginProfile,
  uploadFileStream,
} from "./js/main";
import AlertDialog from "./components/AlertDialog";
// import conf from "./conf";

export default function EditPerson() {
  const [state, setState] = useState();
  const [persons, setPersons] = useState();
  const [person, setPerson] = useState();
  const [firstPriority, setFirstPriority] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      setState({ student_id: params.student_id, profile: Profile });
    })();
  }, [params]);
  useEffect(() => {
    (async () => {
      if (state) {
        let lineId = state.profile.userId;
        let persons = await fetchData("post", "/reg/pickup/persons", {
          student_id: state.student_id,
          line_user_id: lineId,
        });
        if (persons) {
          let person = await persons.filter(
            (j) =>
              j.line_profile && j.line_profile.userId === state.profile.userId
          );
          if (person.length > 0) {
            if (person[0].priority === 1 /*|| conf.on_dev === true*/) {
              setFirstPriority(true);
              for (let s of persons) {
                s.fullname = await mergeFullname(
                  s.prefix,
                  s.first_name,
                  s.middle_name,
                  s.last_name
                );
                s.fullname_en = await mergeFullname(
                  s.prefix_en,
                  s.first_name_en,
                  s.middle_name_en,
                  s.last_name_en
                );
              }
              setPersons(persons);
            } else {
              setPerson(person[0]);
            }
          }
        }
      }
    })();
  }, [state]);

  return (
    <Container style={{ paddingTop: "10px" }}>
      {person ? (
        <>
          <PersonForm person={person} profile={state.profile} />
          {firstPriority === true ? (
            <Button
              variant="contained"
              color="error"
              startIcon={<Icon>undo</Icon>}
              fullWidth
              size="large"
              style={{ marginTop: "15px" }}
              onClick={() => setPerson(null)}
            >
              Back
            </Button>
          ) : (
            <Button
              variant="contained"
              color="error"
              startIcon={<Icon>undo</Icon>}
              fullWidth
              size="large"
              style={{ marginTop: "15px" }}
              onClick={() =>
                navigate(process.env.PUBLIC_URL + "/selectstudent/edit")
              }
            >
              Back
            </Button>
          )}
        </>
      ) : (
        <>
          {persons && firstPriority && (
            <PersonList persons={persons} selectPerson={(p) => setPerson(p)} />
          )}
          <Button
            variant="contained"
            color="error"
            startIcon={<Icon>undo</Icon>}
            fullWidth
            size="large"
            style={{ marginTop: "15px" }}
            onClick={() =>
              navigate(process.env.PUBLIC_URL + "/selectstudent/edit")
            }
          >
            Back
          </Button>
        </>
      )}
      <br />
      <br />
    </Container>
  );
}
function PersonForm({ person, profile }) {
  const [dialogState, setDialogState] = useState({
    open: false,
    body: "Save completed",
  });
  const [photo, setPhoto] = useState();
  const [src, setSRC] = useState();
  const fields = [
    { name: "prefix", label_en: "Prefix", label: "คำนำหน้า", required: true },
    {
      name: "first_name",
      label_en: "First name",
      label: "ชื่อ",
      required: true,
    },
    {
      name: "middle_name",
      label_en: "Middle name",
      label: "ชื่อกลาง",
      required: false,
    },
    {
      name: "last_name",
      label_en: "Last name",
      label: "นามสกุล",
      required: true,
    },
    {
      name: "prefix_en",
      label_en: "Prefix (EN)",
      label: "คำนำหน้าภาษาอังกฤษ",
      required: true,
    },
    {
      name: "first_name_en",
      label_en: "First name (EN)",
      label: "ชื่อภาษาอังกฤษ",
      required: true,
    },
    {
      name: "middle_name_en",
      label_en: "Middle name (EN)",
      label: "ชื่อกลางภาษาอังกฤษ",
      required: false,
    },
    {
      name: "last_name_en",
      label_en: "Last name (EN)",
      label: "นามสกุลภาษาอังกฤษ",
      required: true,
    },
    {
      name: "phone_number",
      label_en: "Phone Number",
      label: "หมายเลขโทรศัพท์",
      required: true,
    },
    {
      name: "relation",
      label_en: "Relation",
      label: "ความสัมพันธ์",
      required: true,
    },
  ];
  const { register, handleSubmit } = useForm({
    values: person,
    shouldUseNativeValidation: true,
  });

  const onSubmit = async (data) => {
    setSRC(null);
    let send = {
      _id: data._id,
      prefix: data.prefix,
      first_name: data.first_name,
      last_name: data.last_name,
      relation: data.relation,
      phone_number: data.phone_number,
      student_id: data.student_id,
      fullname: data.fullname,
      fullname_en: data.fullname_en,
      middle_name: data.middle_name,
      prefix_en: data.prefix_en,
      first_name_en: data.first_name_en,
      middle_name_en: data.middle_name_en,
      last_name_en: data.last_name_en,
    };
    let photo = await upload();
    if (photo) send.photo = photo;
    // if (data.line_profile && data.line_profile.userId)
    //   send.line_user_id = data.line_profile.userId;
    let result = await fetchData(
      "put",
      "/reg/pickup/person",
      send,
      profile.userId
    );
    if (result) setDialogState({ ...dialogState, open: true });
  };

  useEffect(() => {
    (async () => {
      if (person) {
        let photo = person.photo;
        if (!photo) {
          photo = (
            <Icon style={{ fontSize: "10rem", color: "#aaa" }}>person</Icon>
          );
        } else {
          photo = await showUploadImageSrc(person.photo);
          photo = <img src={photo} alt="" style={{ maxWidth: "150px" }} />;
        }
        setPhoto(photo);
      }
    })();
  }, [person]);

  function changeImage(e) {
    var file = e.target.files[0];
    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        let photo = (
          <div>
            <img src={reader.result} alt="" style={{ maxWidth: "150px" }} />
          </div>
        );
        setSRC(reader.result);
        setPhoto(photo);
        /*
        let size = calc_image_size(reader.result);
        if (size < 500) {
          setFileUpload(reader.result);
          setImg(reader.result);
        } else {
          alert("Image file must smaller than 500KB");
        }
      */
      };
    }
  }
  function upload() {
    return new Promise(async (resolve) => {
      let r = null;
      if (src) {
        let result = await uploadFileStream(src);
        if (result && result.file_id) r = "file.php?id=" + result.file_id;
      }
      resolve(r);
    });
  }
  return (
    <div>
      <h2 style={{ fontWeight: 600 }}>Edit Information</h2>
      {photo && (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          {photo}
          <div style={{ paddingTop: "10px" }}>
            <label htmlFor={"person-img"}>
              <input
                accept="image/png,image/jpeg,image/PNG,image/JPEG"
                id={"person-img"}
                type="file"
                onChange={changeImage}
                style={{ display: "none" }}
              />
              <Button
                variant="outlined"
                aria-label="upload picture"
                component="span"
                startIcon={<Icon>photo</Icon>}
              >
                Change Image
              </Button>
            </label>
            {/* <br />
            <Button variant="outlined" onClick={upload}>
              test
            </Button> */}
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          {fields.map((field, key) => (
            <TextField
              key={key}
              {...register(field.name)}
              required={field.required}
              size="small"
              placeholder={field.label}
              InputLabelProps={{ shrink: true }}
              fullWidth
              label={field.label_en}
              variant="outlined"
            />
          ))}
        </Stack>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Icon>save</Icon>}
          fullWidth
          size="large"
          style={{ marginTop: "25px" }}
          type="submit"
        >
          Save
        </Button>
      </form>
      <AlertDialog
        state={dialogState}
        onClose={() => setDialogState({ ...dialogState, open: false })}
      />
    </div>
  );
}

function PersonList({ persons, selectPerson }) {
  return (
    <>
      <h2 style={{ fontWeight: 600 }}>Select Pickup Person</h2>
      <List component={Paper}>
        {persons.map((person, key) => (
          <PersonsItem
            key={key}
            person={person}
            selectPerson={() => selectPerson(person)}
            border={key < persons.length - 1}
          />
        ))}
      </List>
    </>
  );
}

function PersonsItem({ person, border, selectPerson }) {
  const [src, setSrc] = useState();
  useEffect(() => {
    (async () => {
      if (person && person.photo) {
        let src = await showUploadImageSrc(person.photo);
        setSrc(
          <div
            style={{
              backgroundImage: "url(" + src + ")",
              backgroundSize: "cover",
              borderRadius: "50%",
              width: "70px",
              height: "70px",
              border: "1px solid #ccc",
            }}
          ></div>
        );
      }
    })();
  }, [person]);

  return (
    <ListItem
      disablePadding
      style={border ? { borderBottom: "1px solid #ddd" } : {}}
    >
      <ListItemButton onClick={() => selectPerson()}>
        <ListItemIcon style={{ minWidth: "90px", textAlign: "center" }}>
          {src ? <>{src}</> : <Icon style={{ fontSize: "4rem" }}>person</Icon>}
        </ListItemIcon>
        <ListItemText>
          <div style={{ fontWeight: 500 }}>{person.fullname}</div>
          <div style={{ fontSize: ".9rem", color: "#999" }}>
            {person.fullname_en}
          </div>
          <div style={{ fontSize: ".8rem", color: "#090" }}>
            {person.relation}
          </div>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
