import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import liff from "@line/liff";
// import conf from "./conf";
import { fetchData, mergeFullname, getLoginProfile } from "./js/main";
import { Button, Icon, Paper } from "@mui/material";

function getPickupRequest(line_user_id) {
  return new Promise(async (resolve) => {
    let pickups = await fetchData("post", "/reg/pickup/request", {
      line_user_id,
    });
    resolve(pickups);
  });
}

export default function Pickup() {
  const [profile, setProfile] = useState();
  const [students, setStudents] = useState();

  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      setProfile(Profile);
    })();
  }, []);
  /*
  useEffect(() => {
    (async () => {
      await liff.init({
        liffId: conf.liffId,
        // withLoginOnExternalBrowser: true,
      });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          let Profile = await liff.getProfile();
          setProfile(Profile);
        } else {
          if (conf.on_dev === true) {
            setProfile(conf.lineDevProfile);
          } else {
            liff.login();
          }
        }
      });
    })();
  }, []);
  */
  useEffect(() => {
    (async () => {
      if (profile) {
        let students = await getPickupRequest(profile.userId);
        for (let s of students) {
          s.fullname = await mergeFullname(
            s.student_id.prefix,
            s.student_id.first_name,
            s.student_id.middle_name,
            s.student_id.last_name
          );
          s.fullname_en = await mergeFullname(
            s.student_id.prefix_en,
            s.student_id.first_name_en,
            s.student_id.middle_name_en,
            s.student_id.last_name_en
          );
        }
        setStudents(students);
      }
    })();
  }, [profile]);

  function selectStudent(key) {
    let Students = JSON.parse(JSON.stringify(students));
    Students[key].checked = !Students[key].checked;
    setStudents(Students);
  }

  async function sendConfirm() {
    let send = await students
      .filter((j) => j.checked === true)
      .map((st) => {
        return {
          student_id: st.student_id._id,
          pickup_id: st._id,
          line_user_id: profile.userId,
        };
      });
    let result = await fetchData("put", "/reg/pickup/confirm", send);
    if (result) {
      let Students = JSON.parse(JSON.stringify(students));
      for (let pickup of result.filter((j) => j.success === true)) {
        let i = Students.findIndex((j) => j._id === pickup.pickup_id);
        if (i > -1) {
          Students[i].status = "confirm";
          Students[i].checked = false;
        }
      }
      setStudents(Students);
      alert("Confirmed");
    }
  }
  return (
    <Container style={{ paddingTop: "10px" }}>
      {profile && (
        <>
          <h2 style={{ fontWeight: 600 }}>Confirm Pickup</h2>
          {students && (
            <>
              <List component={Paper}>
                {students.length === 0 && (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText>
                        <div
                          style={{
                            textAlign: "center",
                            color: "#999",
                            fontSize: ".9rem",
                            padding: "10px 0px",
                          }}
                        >
                          Not Found Student
                        </div>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                )}
                {students.map((st, key) => (
                  <ListItem
                    key={key}
                    disablePadding
                    style={
                      key < students.length - 1
                        ? { borderBottom: "1px solid #ddd" }
                        : {}
                    }
                  >
                    <ListItemButton
                      onClick={() => selectStudent(key)}
                      disabled={st.status === "confirm"}
                    >
                      <ListItemIcon>
                        {st.status === "confirm" ? (
                          <Icon style={{ fontSize: "2.2rem" }}>
                            thumb_up_off_alt
                          </Icon>
                        ) : (
                          <>
                            {st.checked === true ? (
                              <Icon style={{ color: "#6a6", fontSize: "2rem" }}>
                                done
                              </Icon>
                            ) : (
                              <Icon>radio_button_unchecked</Icon>
                            )}
                          </>
                        )}
                      </ListItemIcon>
                      <ListItemText>
                        <div style={{ fontWeight: 500 }}>{st.fullname}</div>
                        <div style={{ fontSize: ".9rem", color: "#999" }}>
                          {st.fullname_en}
                        </div>
                        <div style={{ fontSize: ".8rem", color: "#090" }}>
                          Pickup person : {st.curator_fullname || "-"}
                        </div>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Button
                sx={{ marginTop: "20px" }}
                fullWidth
                variant="contained"
                startIcon={<Icon>send</Icon>}
                onClick={sendConfirm}
                disabled={
                  !students ||
                  students.filter((j) => j.checked === true).length === 0
                }
              >
                Confirm Pickup
              </Button>
            </>
          )}

          <br />
          <br />
        </>
      )}
    </Container>
  );
}
