import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AlertDialog({ state, onClose }) {
  return (
    <div>
      {state && (
        <Dialog open={state.open}>
          <DialogTitle component="div">{state.title || "Alert"}</DialogTitle>
          <DialogContent sx={state.dialogStyles}>
            <DialogContentText component="div">
              {state.body || ""}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
