import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
// import liff from "@line/liff";
// import conf from "./conf";
import { fetchData, mergeFullname, getLoginProfile } from "./js/main";
import { Button, Icon, Paper } from "@mui/material";

function calPending(students, pickups) {
  return new Promise(async (resolve) => {
    for (let i = 0; i < students.length; i++) {
      let j = await pickups.findIndex(
        (j) => j.student_id._id === students[i]._id
      );
      if (j > -1) {
        students[i].pending = true;
        students[i].curator_fullname = pickups[j].curator_fullname;
        students[i].checked = false;
      } else students[i].pending = false;
    }
    resolve(students);
  });
}
function getPickupRequest(line_user_id) {
  return new Promise(async (resolve) => {
    let pickups = await fetchData("post", "/reg/pickup/request", {
      line_user_id,
    });
    resolve(pickups);
  });
}

export default function Pickup() {
  const [profile, setProfile] = useState();
  const [students, setStudents] = useState();
  // const [txtError, setTxtError] = useState();

  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      setProfile(Profile);
    })();
  }, []);
  /*
  useEffect(() => {
    (async () => {
      await liff.init({
        liffId: conf.liffId,
        // withLoginOnExternalBrowser: true,
      });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          let Profile = await liff.getProfile();
          setProfile(Profile);
        } else {
          if (conf.on_dev === true) {
            setProfile(conf.lineDevProfile);
          } else {
            liff.login();
          }
        }
      });
    })();
  }, []);
  */
  useEffect(() => {
    (async () => {
      let dt = moment().format("HH:mm");
      if (profile) {
        // if (dt < "15:00" || dt > "19:00") {
        //   setTxtError("It's not service time.");
        // } else {
        let pickups = await getPickupRequest(profile.userId);
        let students = await fetchData("post", "/reg/pickup/student", {
          userId: profile.userId,
        });
        students = students.filter((j) => j.start_pickup <= dt);
        for (let s of students) {
          s.fullname = await mergeFullname(
            s.prefix,
            s.first_name,
            s.middle_name,
            s.last_name
          );
          s.fullname_en = await mergeFullname(
            s.prefix_en,
            s.first_name_en,
            s.middle_name_en,
            s.last_name_en
          );
        }
        students = await calPending(students, pickups);
        setStudents(students);
        // }
      }
    })();
  }, [profile]);

  function selectStudent(key) {
    let Students = JSON.parse(JSON.stringify(students));
    Students[key].checked = !Students[key].checked;
    setStudents(Students);
  }

  async function sendRequest() {
    let send = await students
      .filter((j) => j.checked === true)
      .map((st) => {
        return {
          line_user_id: profile.userId,
          student_id: st._id,
          pickup_id: st.pickup_id,
        };
      });
    let result = await fetchData("put", "/reg/pickup/request", send);
    if (result) {
      let Pickups = await getPickupRequest(profile.userId);
      let Students = JSON.parse(JSON.stringify(students));
      Students = await calPending(Students, Pickups);
      setStudents(Students);
      alert("Request Completed");
      // navigate(process.env.PUBLIC_URL + "/confirm");
    }
  }
  return (
    <Container style={{ paddingTop: "10px" }}>
      {profile && (
        <>
          <h2 style={{ fontWeight: 600 }}>Pickup List</h2>
          {/* {txtError && (
            <div style={{ textAlign: "center", lineHeight: "60px" }}>
              {txtError}
            </div>
          )} */}
          {students && (
            <List component={Paper}>
              {students.length === 0 && (
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText>
                      <div
                        style={{
                          textAlign: "center",
                          color: "#999",
                          fontSize: ".9rem",
                          padding: "10px 0px",
                        }}
                      >
                        Not Found Student
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              )}
              {students.map((st, key) => (
                <ListItem
                  key={key}
                  disablePadding
                  style={
                    key < students.length - 1
                      ? { borderBottom: "1px solid #ddd" }
                      : {}
                  }
                >
                  <ListItemButton
                    onClick={() => selectStudent(key)}
                    disabled={st.pending}
                  >
                    <ListItemIcon>
                      {st.pending === true ? (
                        <Icon style={{ fontSize: "2.2rem" }}>access_time</Icon>
                      ) : (
                        <>
                          {st.checked === true ? (
                            <Icon style={{ color: "#6a6", fontSize: "2rem" }}>
                              done
                            </Icon>
                          ) : (
                            <Icon>radio_button_unchecked</Icon>
                          )}
                        </>
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      <div style={{ fontWeight: 500 }}>{st.fullname}</div>
                      <div style={{ fontSize: ".9rem", color: "#999" }}>
                        {st.fullname_en}
                      </div>
                      <div style={{ fontSize: ".8rem", color: "#090" }}>
                        Pickup person : {st.curator_fullname || "-"}
                      </div>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
          <Button
            sx={{ marginTop: "20px" }}
            fullWidth
            variant="contained"
            startIcon={<Icon>call_missed_outgoing</Icon>}
            onClick={sendRequest}
            disabled={
              !students ||
              students.filter((j) => j.checked === true).length === 0
            }
          >
            Request Pickup
          </Button>
          <br />
          <br />
        </>
      )}
    </Container>
  );
}
