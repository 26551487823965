import React, { useEffect, useState } from "react";
// import liff from "@line/liff";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import Paper from "@mui/material/Paper";
import { Button, Container, Grid } from "@mui/material";
// import conf from "./conf";
import {
  fetchData,
  mergeFullname,
  getLoginProfile,
  showUploadImageSrc,
} from "./js/main";

export default function LineLogin() {
  const [profile, setProfile] = useState();
  const [otp, setOTP] = useState("");
  const [student, setStudent] = useState();
  const [confirmStat, setConfirmStat] = useState(false);
  const [img, setImg] = useState();

  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      setProfile(Profile);
    })();
  }, []);
  /*
  useEffect(() => {
    (async () => {
      await liff.init({
        liffId: conf.liffId,
        // withLoginOnExternalBrowser: true,
      });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          let Profile = await liff.getProfile();
          setProfile(Profile);
        } else {
          if (conf.on_dev === true) {
            setOTP("1111");
            setProfile(conf.lineDevProfile);
          } else {
            liff.login();
          }
        }
      });
    })();
  }, []);
  */

  async function getOTPStudent() {
    if (otp !== "") {
      let person = await fetchData("post", "/reg/pickup/verify/otp", {
        otp,
      });
      if (person) {
        let Img = {};
        if (person.pickup_person.photo) {
          Img.person_photo = await showUploadImageSrc(
            person.pickup_person.photo
          );
        }
        if (person.photo_url) {
          Img.student_photo = await showUploadImageSrc(person.photo_url);
        }
        setImg(Img);
        let fullname = await mergeFullname(
          person.prefix,
          person.first_name,
          person.middle_name,
          person.last_name
        );
        let fullname_en = await mergeFullname(
          person.prefix_en,
          person.first_name_en,
          person.middle_name_en,
          person.last_name_en
        );

        let pickup_fullname = await mergeFullname(
          person.pickup_person.prefix,
          person.pickup_person.first_name,
          person.pickup_person.middle_name,
          person.pickup_person.last_name
        );

        let pickup_fullname_en = await mergeFullname(
          person.pickup_person.prefix_en,
          person.pickup_person.first_name_en,
          person.pickup_person.middle_name_en,
          person.pickup_person.last_name_en
        );
        setStudent({
          ...person,
          fullname,
          fullname_en,
          pickup_fullname,
          pickup_fullname_en,
        });
      }
    } else alert("Verify failed");
  }

  async function confirm() {
    let result = await fetchData("put", "/reg/pickup/verify/otp", {
      otp,
      line_profile: profile,
    });
    if (result.success && result.success === true) setConfirmStat(true);
  }
  return (
    <Container style={{ textAlign: "center" }}>
      {profile && student ? (
        <>
          <h2 style={{ margin: "20px", fontWeight: 600 }}>
            Confirm for activate pickup student
          </h2>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper style={{ padding: "20px 0px" }}>
                {img.person_photo ? (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundImage: "url(" + img.person_photo + ")",
                      backgroundSize: "cover",
                      margin: "auto  auto 10px auto",
                      borderRadius: "50%",
                    }}
                  ></div>
                ) : (
                  <Icon sx={{ fontSize: "5rem", color: "#777" }}>
                    supervisor_account
                  </Icon>
                )}
                <h3 style={{ margin: "0px", fontWeight: 600 }}>Pickup Name</h3>
                <div style={{ color: "#999", fontSize: ".9rem" }}>
                  <div
                    style={{
                      fontSize: "1.2rem",
                      paddingBottom: "5px",
                      fontWeight: 600,
                      color: "#00f",
                    }}
                  >
                    {student.pickup_fullname}
                  </div>
                  {student.pickup_fullname_en}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper style={{ padding: "20px 0px" }}>
                {img.student_photo ? (
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundImage: "url(" + img.student_photo + ")",
                      backgroundSize: "cover",
                      margin: "auto  auto 10px auto",
                      borderRadius: "50%",
                    }}
                  ></div>
                ) : (
                  <Icon sx={{ fontSize: "5rem", color: "#777" }}>face</Icon>
                )}
                <h3 style={{ fontWeight: 600, margin: "0px" }}>Student Name</h3>
                <div
                  style={{
                    fontSize: "1.2rem",
                    paddingBottom: "5px",
                    fontWeight: 600,
                    color: "#00f",
                  }}
                >
                  {student.fullname}
                </div>
                <div style={{ color: "#999", fontSize: ".9rem" }}>
                  {student.fullname_en}
                </div>
              </Paper>
            </Grid>
          </Grid>
          {confirmStat === false ? (
            <div>
              <Button
                variant="contained"
                startIcon={<Icon>done</Icon>}
                fullWidth
                size="large"
                style={{ marginTop: "25px" }}
                onClick={confirm}
              >
                Confirm
              </Button>
              <br />
              <Button
                variant="contained"
                color="error"
                startIcon={<Icon>undo</Icon>}
                fullWidth
                size="large"
                style={{ marginTop: "15px" }}
                onClick={() => setStudent(null)}
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div
              style={{
                color: "#393",
                marginTop: "20px",
                fontSize: "1.5rem",
                fontWeight: 600,
              }}
            >
              Confirmed
            </div>
          )}
        </>
      ) : (
        <>
          <h3 style={{ margin: "10px", fontWeight: 600 }}>กรอกรหัส OTP</h3>
          <div>เพื่อเพิ่มสิทธิการรับนักเรียน</div>
          <br />
          <TextField
            size="small"
            label="OTP"
            value={otp}
            onChange={(e) => setOTP(e.target.value.trim())}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            placeholder="รหัส OTP"
            fullWidth
            inputProps={{
              style: {
                fontSize: "1.5rem",
                fontWeight: 500,
                textAlign: "center",
              },
            }}
          />
          <br />
          <Button
            variant="contained"
            startIcon={<Icon>person_add_alt1</Icon>}
            fullWidth
            size="large"
            style={{ marginTop: "15px" }}
            onClick={getOTPStudent}
          >
            Add Student
          </Button>
        </>
      )}
      <br />
      <br />
    </Container>
  );
}
