import React, { Fragment, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import conf from "./conf";

// import liff from "@line/liff";
// import conf from "./conf";
import { useForm } from "react-hook-form";
import { fetchData, getLoginProfile } from "./js/main";
import AlertDialog from "./components/AlertDialog";
import axios from "axios";
import moment from "moment/moment";

export default function EditPerson() {
  /*
  const sendGroups = [
    {
      label: "ทะเบียน",
      accounts: [
        "pratchaya.cmu@gmail.com",
        "ck.gman007@gmail.com",
        "chitsanupong.k@tonkla.ac.th",
        "jirakana@tonkla.ac.th",
      ],
    },
    {
      label: "การเงิน",
      accounts: [
        "ck.gman007@gmail.com",
        "petcharat.k@tonkla.ac.th",
        "suree.o@tonkla.ac.th",
        "sutananj@tonkla.ac.th",
        "wanidaj@tonkla.ac.th",
      ],
    },
  ];
  */

  const [sendGroups, setSendGroups] = useState([]);
  const [finish, setFinish] = useState(false);
  const [profile, setProfile] = useState();
  const [file, setFile] = useState();
  const [tabNo, setTabNo] = useState(0);
  const [dialogState, setDialogState] = useState({
    open: false,
    title: "Dialog",
    body: "Send completed",
  });
  const { register, handleSubmit } = useForm({
    // values: person,
    shouldUseNativeValidation: true,
  });

  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      if (Profile) {
        setProfile(Profile);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (profile) {
        console.log(profile);
        let groups = await fetchData("get", "/parents/message/group");
        console.log(groups);
        let sendGroups = [];
        for (let group of groups) {
          let obj = { label: group.name, accounts: [] };
          for (let account of group.accounts) {
            obj.accounts.push(account.usn);
          }
          sendGroups.push(obj);
        }

        setSendGroups(sendGroups);
      }
    })();
  }, [profile]);

  /*
  useEffect(() => {
    (async () => {
      await liff.init({
        liffId: conf.liffId,
        // withLoginOnExternalBrowser: true,
      });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          let Profile = await liff.getProfile();
          setProfile(Profile);
        } else {
          if (conf.on_dev === true) {
            setProfile(conf.lineDevProfile);
          } else {
            liff.login();
          }
        }
      });
    })();
  }, []);
  */

  async function onSubmit(data) {
    let send = JSON.parse(JSON.stringify(data));
    send.accounts = sendGroups[data.to].accounts;
    if (file) send.file = { file_path: await upload(), file_name: file.name };
    delete send.to;
    let result = await fetchData(
      "put",
      "/reg/pickup/message",
      send,
      profile.userId
    );
    if (result) {
      setDialogState({
        ...dialogState,
        body: "Send message to " + sendGroups[data.to].label + " completed",
        open: true,
      });
      setFinish(true);
    }
  }

  function selectFile(e) {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  }

  function upload() {
    return new Promise(async (resolve) => {
      if (!file) {
        resolve(null);
      }

      const formData = new FormData();
      formData.append("file", file);
      let result = await axios.post(
        conf.endpoint.file + "/uploadfile.php",
        formData
      );
      if (result.data && result.data.success === true && result.data.file_id) {
        resolve(conf.endpoint.file + "/file.php?id=" + result.data.file_id);
      } else {
        resolve(null);
      }
    });
  }

  return (
    <Container style={{ paddingTop: "10px" }}>
      {profile && (
        <>
          <AlertDialog
            state={dialogState}
            onClose={() => setDialogState({ ...dialogState, open: false })}
          />
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabNo}
              onChange={(val, val2) => setTabNo(val2)}
              aria-label="basic tabs example"
            >
              <Tab label="Send Message" />
              <Tab label="History" />
            </Tabs>
          </Box>
          {
            {
              0: (
                <>
                  <h2 style={{ fontWeight: 600 }}>Send Message</h2>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={2}>
                      <FormControl fullWidth>
                        <InputLabel shrink>Send to</InputLabel>
                        <Select native label="Send to" {...register("to")}>
                          {sendGroups.map((group, key) => (
                            <option key={key} value={key}>
                              {group.label}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        {...register("subject")}
                        required={true}
                        placeholder="ชื่อเรื่อง"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="Subject"
                        variant="outlined"
                      />
                      <TextField
                        {...register("message")}
                        required={true}
                        placeholder="ข้อความ"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={4}
                      />

                      <label htmlFor={"person-img"}>
                        <input
                          accept="image/png,image/jpeg,image/PNG,image/JPEG,application/pdf,application/PDF,
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          id={"person-img"}
                          type="file"
                          onChange={selectFile}
                          style={{ display: "none" }}
                        />
                        <Button
                          variant="outlined"
                          aria-label="upload picture"
                          component="span"
                          startIcon={<Icon>attach_file</Icon>}
                        >
                          Select File
                        </Button>
                        <span style={{ paddingLeft: "20px", fontWeight: 600 }}>
                          {file && file.name}
                        </span>
                      </label>
                      <div
                        style={{
                          color: "#0a0",
                          fontSize: ".9rem",
                          marginTop: "10px",
                        }}
                      >
                        *.png, *.jpg, *.pdf, *.docx, *.xlsx
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Icon>send</Icon>}
                        fullWidth
                        size="large"
                        style={{ marginTop: "25px" }}
                        type="submit"
                        disabled={finish}
                      >
                        Send
                      </Button>
                    </Stack>
                  </form>
                </>
              ),
              1: <HistoryTab profile={profile} />,
            }[tabNo]
          }
        </>
      )}
      <br />
      <br />
    </Container>
  );
}

function HistoryTab({ profile }) {
  const [tickets, setTickets] = useState();
  useEffect(() => {
    (async () => {
      let tickets = await fetchData(
        "post",
        "/reg/pickup/message/history",
        null,
        profile.userId
      );
      setTickets(tickets);
    })();
  }, [profile]);

  return (
    <div>
      {tickets && (
        <>
          <h2 style={{ fontWeight: 600 }}>Ticket History</h2>
          {tickets.count === 0 ? (
            <div style={{ textAlign: "center", color: "#999" }}>
              Not fount ticket
            </div>
          ) : (
            <>
              <List component={Paper}>
                {tickets.result.map((ticket, key) => (
                  <TicketItem
                    ticket={ticket}
                    profile={profile}
                    key={key}
                    border={key < tickets.count - 1}
                  />
                ))}
              </List>
            </>
          )}
        </>
      )}
      <br />
      <br />
      <br />
    </div>
  );
}

function TicketItem(props) {
  const [ticket, setTicket] = useState();
  const [replies, setReplies] = useState();
  const [form, setForm] = useState();
  useEffect(() => {
    if (props.ticket) {
      setForm({
        message: "",
        send_name: props.ticket.send_name,
        ticket_id: props.ticket._id,
      });
      setTicket(props.ticket);
    }
  }, [props]);

  async function showReply() {
    setTicket({ ...ticket, showReply: !ticket.showReply });
    let Replies = await fetchData(
      "get",
      "/reg/pickup/message/reply/" + ticket._id,
      null,
      props.profile.userId
    );
    setReplies(Replies);
  }
  async function sendReply() {
    let result = await fetchData(
      "put",
      "/reg/pickup/message/reply",
      form,
      props.profile.userId
    );
    if (result) {
      setForm({ ...form, message: "" });
      let Replies = JSON.parse(JSON.stringify(replies));
      Replies.push(result);
      setReplies(Replies);
    }
  }
  return (
    <Fragment>
      {ticket && (
        <>
          <ListItem
            disablePadding
            style={
              props.border === true
                ? {
                    borderBottom: "1px solid #ddd",
                  }
                : {}
            }
          >
            <ListItemButton onClick={showReply}>
              <ListItemIcon>
                <Icon style={{ fontSize: "2rem" }}>mail</Icon>
              </ListItemIcon>
              <ListItemText>
                <div style={{ fontWeight: 500 }}>{ticket.subject}</div>
                <div style={{ fontSize: ".8rem", color: "#090" }}>
                  {moment(ticket.create_time).format("LLLL")}
                </div>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Collapse in={ticket.showReply}>
            <ReplyItem reply={ticket} />
            {replies ? (
              <>
                {replies.map((reply) => (
                  <ReplyItem key={reply._id} reply={reply} />
                ))}

                <ListItemAvatar>
                  <ListItemText
                    style={{
                      padding: "5px 10px",
                    }}
                  >
                    <TextField
                      style={{ background: "#fff" }}
                      label="Reply"
                      InputLabelProps={{ shrink: true }}
                      multiline
                      row={2}
                      value={form.message}
                      onChange={(e) =>
                        setForm({ ...form, message: e.target.value })
                      }
                      fullWidth
                    />
                    <div style={{ textAlign: "right" }}>
                      <Button
                        variant="contained"
                        endIcon={<Icon>send</Icon>}
                        style={{ marginTop: "10px" }}
                        onClick={sendReply}
                      >
                        Reply
                      </Button>
                    </div>
                  </ListItemText>
                </ListItemAvatar>
              </>
            ) : (
              <>
                <LinearProgress />
              </>
            )}
          </Collapse>
        </>
      )}
    </Fragment>
  );
}

function ReplyItem({ reply }) {
  return (
    <ListItemAvatar key={reply._id}>
      <ListItemText
        style={{
          padding: "5px 10px",
          margin: "0px",
        }}
      >
        <Typography
          style={{
            backgroundColor: "#fff",
            borderRadius: "3px",
            padding: "10px 10px",
            border: "1px solid #ccc",
            whiteSpace: "pre-line",
          }}
          component="div"
        >
          <Typography
            color="primary"
            component="div"
            style={{ fontSize: ".9rem", fontWeight: 500 }}
          >
            {reply.send_name}
          </Typography>
          <div
            style={{
              fontSize: ".8rem",
              color: "#999",
              paddingBottom: "5px",
              borderBottom: "1px solid #ddd",
            }}
          >
            {moment(reply.create_time).format("LLLL")}
          </div>
          <div style={{ fontSize: ".9rem", paddingTop: "10px" }}>
            {`${reply.message}`}
          </div>
        </Typography>
      </ListItemText>
    </ListItemAvatar>
  );
}
