import React, { useEffect, useState } from "react";
// import liff from "@line/liff";
// import conf from "./conf";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import {
  fetchData,
  mergeFullname,
  showUploadImageSrc,
  getLoginProfile,
} from "./js/main";
import { useParams, useNavigate } from "react-router-dom";

export default function EditPerson() {
  const [profile, setProfile] = useState();
  const [students, setStudents] = useState();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let Profile = await getLoginProfile();
      setProfile(Profile);
    })();
  }, []);
  /*
  useEffect(() => {
    (async () => {
      await liff.init({
        liffId: conf.liffId,
        // withLoginOnExternalBrowser: true,
      });
      liff.ready.then(async () => {
        if (liff.isLoggedIn()) {
          let Profile = await liff.getProfile();
          setProfile(Profile);
        } else {
          if (conf.on_dev === true) {
            setProfile(conf.lineDevProfile);
          } else {
            liff.login();
          }
        }
      });
    })();
  }, []);
  */

  useEffect(() => {
    (async () => {
      if (profile) {
        let students = await fetchData("post", "/reg/pickup/student", {
          userId: profile.userId,
        });
        for (let s of students) {
          s.fullname = await mergeFullname(
            s.prefix,
            s.first_name,
            s.middle_name,
            s.last_name
          );
          s.fullname_en = await mergeFullname(
            s.prefix_en,
            s.first_name_en,
            s.middle_name_en,
            s.last_name_en
          );
        }
        setStudents(students);
      }
    })();
  }, [profile]);

  function selectStudent(key) {
    navigate(
      process.env.PUBLIC_URL + "/" + params.path + "/" + students[key]._id
    );
  }

  return (
    <Container style={{ paddingTop: "10px" }}>
      {profile && (
        <Box sx={{ flexGrow: 1 }}>
          <h2 style={{ fontWeight: 600 }}>Select Student</h2>

          {students && (
            <>
              <List component={Paper}>
                {students.length === 0 && (
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemText>
                        <div
                          style={{
                            textAlign: "center",
                            color: "#999",
                            fontSize: ".9rem",
                            padding: "10px 0px",
                          }}
                        >
                          Not Found Student
                        </div>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                )}
                {students.map((student, key) => (
                  <StudentItem
                    key={key}
                    student={student}
                    selectStudent={() => selectStudent(key)}
                    border={key < students.length - 1}
                  />
                ))}
              </List>
            </>
          )}
        </Box>
      )}
    </Container>
  );
}

function StudentItem({ student, border, selectStudent }) {
  const [src, setSrc] = useState();
  useEffect(() => {
    (async () => {
      if (student) {
        let src = await showUploadImageSrc(student.photo_url);
        setSrc(
          <div
            style={{
              backgroundImage: "url(" + src + ")",
              backgroundSize: "cover",
              borderRadius: "50%",
              width: "70px",
              height: "70px",
              border: "1px solid #ccc",
            }}
          ></div>
        );
      }
    })();
  }, [student]);

  return (
    <ListItem
      disablePadding
      style={border ? { borderBottom: "1px solid #ddd" } : {}}
    >
      <ListItemButton onClick={() => selectStudent()}>
        <ListItemIcon style={{ minWidth: "90px", textAlign: "center" }}>
          {src ? (
            <>{src}</>
          ) : (
            <Icon style={{ fontSize: "3.5rem" }}>person</Icon>
          )}
        </ListItemIcon>
        <ListItemText>
          <div style={{ fontWeight: 500 }}>{student.fullname}</div>
          <div style={{ fontSize: ".9rem", color: "#999" }}>
            {student.fullname_en}
          </div>
          <div style={{ fontSize: ".8rem", color: "#090" }}>
            Group : {student.group_name || "-"}
          </div>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
